<template>
  <b-modal :active="true" has-modal-card trap-focus aria-role="dialog" aria-modal>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Refund Order {{ fulfilment.Id }}</p>
      </header>
      <section class="modal-card-body" v-if="canRefund">
        <span>
          Refunding product
          <b>{{ fulfilment.ProductName }}</b>
          for
          <b>{{ fulfilment.Data.primary_contact.primary_contact_email }}</b>
        </span>
        <br />
        <br />
        <span>
          Product price was
          <b>{{ offerPriceWithCurrency }}</b>
        </span>
        <table class="table" style="margin-top: 15px;">
          <tr>
            <td colspan="2">Cart details {{ hasMultipleItems ? "(multiple item cart)" : "" }}</td>
          </tr>
          <tr>
            <th>Subtotal</th>
            <td>{{ subtotal.toFixed(2) }}</td>
          </tr>
          <tr>
            <th>Discount</th>
            <td>{{ discount_amount ? discount_amount.toFixed(2) : "0.00" }}</td>
          </tr>
          <tr>
            <th>Postage</th>
            <td>{{ postage ? postage.toFixed(2) : "0.00" }}</td>
          </tr>
          <tr>
            <th>Grand Total</th>
            <td>{{ grand_total.toFixed(2) }}</td>
          </tr>
        </table>

        <div
          v-if="discount_amount && discount_amount > 0"
        >This order has a discount applied to cart, consider this when setting refund amount</div>
        <div
          v-if="subtotal > productPrice"
        >The cart price is higher than the item price, This order most likely contained mutiple items in it, consider this when setting refund amount</div>

        <p>Amount to refund</p>
        <div class="field">
          <div class="control">
            <input
              v-model="amountToRefund"
              placeholder="Amount to refund e.g 19.99"
              maxlength="20"
              required="required"
              class="input"
            />
          </div>
        </div>

        <p>Reason for Refund(s)</p>
        <div class="field">
          <div class="control">
            <input
              v-model="refundReason"
              placeholder="e.g. reason for this refund"
              maxlength="200"
              required="required"
              class="input"
            />
          </div>
          <p class="help is-danger"></p>
        </div>
      </section>
      <section
        class="modal-card-body"
        v-else
      >This order is too old to refund from here, please manually process it</section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="Close">Cancel</button>
        <b-button
          class="button is-danger"
          v-if="canRefund"
          :disabled="refundReason.length === 0 || amountToRefund.length === 0"
          v-on:click="confirmRefundDialog"
          :loading="isLoading"
        >Refund</b-button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import { baseMixin } from "../../../mixins/baseMixin.js";
//fulfilment-info-for-bureau
export default {
  name: "fulfilmentRefundDialog",
  mixins: [baseMixin],
  //canCancel
  components: {},
  props: {
    fulfilment: Object
  },
  data() {
    return {
      isLoading: false,
      refundReason: "",
      amountToRefund: 0,
      APIUrl: process.env.VUE_APP_APIURL
    };
  },
  created: function() {
    if (this.hasRefundObject) {
      this.amountToRefund = this.hasMultipleItems
        ? this.productPrice.toFixed(2)
        : this.grand_total.toFixed(2);
    } else {
      this.amountToRefund = this.productPrice.toFixed(2);
    }
  },
  methods: {
    Close: function() {
      //emit close!
      this.$emit("close");
    },
    confirmRefundDialog() {
      var url =
        this.APIUrl +
        "/fulfilment/" +
        this.fulfilment.Id +
        "/refund" +
        "?reason=" +
        this.refundReason +
        "&amountInPence=" +
        this.amountToRefundInPence;

      var that = this;
      that.isLoading = true;

      this.axios
        .put(url)
        .then(response => {
          var msg =
            "Refund completed for order " +
            that.fulfilment.Id +
            " for amount" +
            that.amountToRefundInPence;
          that.$toast.open(msg);
          that.$emit("fulfilmentUpdate", response.data);
          that.Close();
          that.isLoading = false;
        })
        .catch(function(error) {
          console.log(error);

          that.$toast.open("oops something went wrong, check logs for details");
        });
    }
  },
  computed: {
    amountToRefundInPence() {
      return parseInt((parseFloat(this.amountToRefund) * 100).toFixed(2));
    },
    productPrice() {
      return parseFloat(this.fulfilment.Data.products_info.offer_price);
    },
    //amounts for whole cart
    subtotal() {
      return parseFloat(this.fulfilment.Data.other.subtotal);
    },
    discount_amount() {
      return parseFloat(this.fulfilment.Data.other.discount_amount);
    },
    postage() {
      return parseFloat(this.fulfilment.Data.other.postage);
    },
    grand_total() {
      return parseFloat(this.fulfilment.Data.other.grand_total);
    },
    discountAmount() {
      return this.fulfilment.Data.other.discount_amount !== ""
        ? parseFloat(this.fulfilment.Data.other.discount_amount)
        : "0.00";
    },
    offerPriceWithCurrency() {
      return (
        this.productPrice.toFixed(2) +
        " " +
        this.fulfilment.Data.products_info.currency_code
      );
    },

    canRefund() {
      var dateOrdersGotFullCartOrderAmounts = new Date("2020-08-11 08:45:00");
      return (
        new Date(this.fulfilment.CreatedAt) > dateOrdersGotFullCartOrderAmounts
      );
    },
    hasMultipleItems() {
      return this.productPrice < this.subtotal;
    },
    hasRefundObject() {
      return this.fulfilment.Data.meta_info.refund !== null;
    },
    showCartDetails() {
      return (
        this.hasMultipleItems || this.discount_amount > 0 || this.postage > 0
      );
    }
  }
};
</script>
